@import "/styles/variables.scss";
.NoMatch {
  -ms-grid-column: 1;
  -ms-grid-row: 2;
  grid-column: 1;
  grid-row: 2;
  text-align: center;

  h1 {
    color: #000000;
  }

  .cta {
    border-width: 2px;
    border-style: solid;
    cursor: pointer;
    text-decoration: none;
  }
  
  .primaryCta {
    color: white;
    background-color: $primary-blue;
    border-color: $primary-blue;
  }
}

@media screen and (min-width: 0px){

  .NoMatch {
    text-align: center;
    font-size: $mobile-text;
    margin-bottom: 30px;

    h1 {
      margin: 30px auto 0px;
    }

    h4 {
      max-width: 300px;
      margin: 20px auto;
    }

    .cta {
      display: block;
      width: 60%;
      margin: 10px auto;
      border-width: 1px;
      border-style: solid;
      border-radius: 4px;
      padding: 8px 12px;
    }
  
    .cta svg {
      font-size: 18px;
      margin-bottom: -3px;
      margin-left: 5px;
    }

  }

}

@media screen and (min-width: 500px){

  .NoMatch {
    font-size: $tablet-text;
    margin-bottom: auto;

    h1 {
      margin: 40px auto 0px;
    }

    h4 {
      max-width: 500px;
      margin: 20px auto;
    }

    .cta {
      width: 45%;
      max-width: 325px;
      margin: 10px auto;
      border-width: 2px;
      border-style: solid;
      padding: 5px 14px;
    }
  
    .cta svg {
      font-size: 20px;
      margin-bottom: -3px;
      margin-left: 6px;
    }
    
  }

}

@media screen and (min-width: 950px){

  .NoMatch {
    font-size: $web-text;

    h1 {
      margin: 70px auto 0px;
    }

    h4 {
      max-width: 900px;
      margin: 20px auto;
    }

    .cta {
      display: inline-block;
      width: auto;
      margin: 0px 10px;
      border-width: 2px;
      border-style: solid;
      border-radius: 6px;
      padding: 6px 15px;
    }
  
    .cta svg {
      font-size: 25px;
      margin-bottom: -5px;
      margin-left: 6px;
    }

  }

}